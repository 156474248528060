import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.scss'
import './style.sass'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div id="pageGrid">
        <Header siteTitle={data.site.siteMetadata.title} />
        <div id="content">
          {children}
        </div>
        <footer id="footer">
          <div id="footer-content">
            <div>
              © {new Date().getFullYear()} <a href="https://.aph.works/?utm_source=InterestCalc&utm_medium=footer" target="_blank" rel="noopener noreferrer">APH</a>
              &nbsp;|&nbsp;
              <a href="https://gitlab.com/auntphil/interest-calculator" target="_blank" rel="noopener noreferrer" >GitLab</a>
            </div>
            <div className="disclaimer">The Data provided by this tool is for informational purposes only. Information provided does not constitute financial advice or help.</div>
          </div>
        </footer>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
